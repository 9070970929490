import React from 'react';

import {
  ContentGroup,
  Heading,
  Grid,
  GridItem,
  Paragraph,
  Link,
  Hr,
  List,
  ListItem,
  Main,
  Strong,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import manageYourMortgageDoor from 'assets/common/borrow-more.jpg';
import manageYourMortgageScreen from 'assets/common/manageYourMortgageScreen.png';
import timeToSwitchImage from 'assets/common/switch-deal.jpg';
import ContentBox from 'client/components/contentBox/ContentBox';
import GradientBox from 'client/components/gradientBox/GradientBox';
import { StyledLink } from 'client/components/styledLink/StyledLink';
import { Helmet } from 'react-helmet';


import { ManageYourMortgageContent } from './ManageYourMortgagePage.config';
import {
  StyledManageYourMortgageOnline,
  StyledButton,
} from './ManageYourMortgagePage.styled';
import * as routes from '../../manifest';

export default function ManageYourMortgagePage() {
  const {
    manageYourMortgage,
    manageYourMortgageOnline,
    changingMortgageTerm,
    makingPaymentsOrOverpaying,
    requestingCopyDocuments,
    changeAnyDetails,
    residentialMortgages,
    metaContent,
  } = useContent<ManageYourMortgageContent>();

  return (
    <Main>
      <Helmet>
        <title>{metaContent.title}</title>
        <meta name="description" content={metaContent.description} />
      </Helmet>
      <GradientBox>
        <ContentBox
          imgSrc={manageYourMortgageScreen}
          leftAlignImage={false}
          imgWidth="200px"
          marginBottom="05"
          marginTop="05"
        >
          <Heading inverse size="s7" marginBottom="05" as="h1"
            data-testid='manage-your-mortgage-heading'>
            {manageYourMortgage.heading}
          </Heading>
          <Paragraph inverse weight="bold" size="s2">
            {manageYourMortgage.headingIntroText}
          </Paragraph>
          <Paragraph inverse marginBottom="08">
            {manageYourMortgage.headingIntroText2}
          </Paragraph>
        </ContentBox>
      </GradientBox>

      <ContentGroup>
        <Grid>
          <GridItem lg={8} md={8}>
            <Heading marginTop="02" size="s5" as="h2"
              data-testid='manage-your-mortgage-online-heading'>
              {manageYourMortgageOnline.heading}
            </Heading>
            <Paragraph size="s2">
              {manageYourMortgageOnline.heading2IntroText}
            </Paragraph>
            <StyledButton
              width="content"
              href={manageYourMortgageOnline.signInBtnHref}
            >
              {manageYourMortgageOnline.signInBtnText}
            </StyledButton>
          </GridItem>
        </Grid>

        <Grid>
          <GridItem lg={4} md={4} sm={12}>
            <StyledManageYourMortgageOnline src={manageYourMortgageDoor} />
            <Heading size="s2" marginBottom="02" as="h3">
              {manageYourMortgageOnline.readyToBorrowMore.title}
            </Heading>

            <Paragraph size="s1">
              {manageYourMortgageOnline.borrowingMore.paragraph}
            </Paragraph>
            <Paragraph size="s1">
              {manageYourMortgageOnline.readyToBorrowMore.text}
            </Paragraph>
            <Paragraph size="s1" weight="bold" marginBottom="none">
              {manageYourMortgageOnline.readyToBorrowMore.secondaryTitle}
            </Paragraph>
            <Link
              external
              href={manageYourMortgageOnline.readyToBorrowMore.link.url}
              size="s1"
            >
              {manageYourMortgageOnline.readyToBorrowMore.link.title}
            </Link>
          </GridItem>

          <GridItem lg={4} md={4} sm={12}>
            <StyledManageYourMortgageOnline src={timeToSwitchImage} />
            <Heading size="s2" as="h3" marginBottom="02">
              {manageYourMortgageOnline.timeToSwitchYourDeal.title}
            </Heading>
            <Paragraph size="s1">
              {manageYourMortgageOnline.timeToSwitchYourDeal.text}
            </Paragraph>
            <Paragraph size="s1">
              {manageYourMortgageOnline.timeToSwitchYourDeal.text2}
            </Paragraph>
            <Paragraph size="s1" weight="bold" marginBottom="none">
              {manageYourMortgageOnline.timeToSwitchYourDeal.secondaryTitle}
            </Paragraph>
            <Link
              external
              href={manageYourMortgageOnline.timeToSwitchYourDeal.link.url}
              size="s1"
            >
              {manageYourMortgageOnline.timeToSwitchYourDeal.link.title}
            </Link>
          </GridItem>
        </Grid>

        <Hr />

        <Heading size="s5" marginBottom="05" as="h2"
          data-testid='change-mortgage-term-heading'>
          {changingMortgageTerm.title}
        </Heading>
        <Paragraph size="s2">{changingMortgageTerm.introText}</Paragraph>
        <Paragraph weight="bold" size="s2">
          {changingMortgageTerm.listItemTitle}
        </Paragraph>
        <List data-testid="change-mortgage-term-list">
          {changingMortgageTerm.listItems.map((item: string, index) => (
            <ListItem
              data-testid={`change-mortgage-term-list-item-${index}`}
              key={item}
            >
              {item}
            </ListItem>
          ))}
        </List>
        <Paragraph size="s2">{changingMortgageTerm.text2}</Paragraph>
        <Paragraph size="s2">{changingMortgageTerm.text3}</Paragraph>

        <Hr />

        <Heading size="s5" marginBottom="05" as="h2"
          data-testid="making-payments-heading">
          {makingPaymentsOrOverpaying.title}
        </Heading>
        <Paragraph size="s2">{makingPaymentsOrOverpaying.text}</Paragraph>

        <Hr />

        <Heading size="s5" marginBottom="05" as="h2"
          data-testid='requesting-copy-documents-heading'>
          {requestingCopyDocuments.title}
        </Heading>
        <Paragraph size="s2">{requestingCopyDocuments.text1}</Paragraph>
        <Paragraph size="s2">{requestingCopyDocuments.text2}</Paragraph>
        <Paragraph size="s2">{requestingCopyDocuments.text3}</Paragraph>

        <Paragraph weight="bold" size="s2" marginBottom="none">
          {requestingCopyDocuments.boldText}
        </Paragraph>
        <Paragraph size="s2" marginBottom="none">
          {requestingCopyDocuments.addressLine1}
        </Paragraph>
        <Paragraph size="s2" marginBottom="none">
          {requestingCopyDocuments.addressLine2}
        </Paragraph>
        <Paragraph size="s2" marginBottom="none">
          {requestingCopyDocuments.addressLine3}
        </Paragraph>
        <Paragraph size="s2">{requestingCopyDocuments.addressLine4}</Paragraph>
        <Paragraph size="s2" marginBottom="none">
          {requestingCopyDocuments.text4}
        </Paragraph>

        <Hr />

        <Heading size="s5" marginBottom="05" as="h2"
          data-testid='change-details-heading'>
          {changeAnyDetails.title}
        </Heading>
        <Paragraph size="s2" weight="bold" marginBottom="none">
          {changeAnyDetails.boldIntroText}
        </Paragraph>
        <Paragraph size="s2" marginBottom="none">
          {changeAnyDetails.addressLine1}
        </Paragraph>
        <Paragraph size="s2" marginBottom="none">
          {changeAnyDetails.addressLine2}
        </Paragraph>
        <Paragraph size="s2" marginBottom="none">
          {changeAnyDetails.addressLine3}
        </Paragraph>
        <Paragraph size="s2">{changeAnyDetails.addressLine4}</Paragraph>

        <Paragraph size="s2" weight="bold" marginBottom="none">
          {changeAnyDetails.boldText2}
        </Paragraph>
        <List data-testid="change-mortgage-term-list">
          {changeAnyDetails.listItems.map((item: string, index) => (
            <ListItem
              data-testid={`change-mortgage-term-list-item-${index}`}
              key={item}
            >
              {item}
            </ListItem>
          ))}
        </List>

        <Paragraph size="s2" weight="bold" marginBottom="none">
          {changeAnyDetails.boldText3}
        </Paragraph>
        <List data-testid="change-mortgage-term-list">
          {changeAnyDetails.listItems2.map((item: string, index) => (
            <ListItem
              data-testid={`change-mortgage-term-list-item-${index}`}
              key={item}
            >
              {item}
            </ListItem>
          ))}
        </List>

        <Paragraph size="s2">{changeAnyDetails.text}</Paragraph>
        <Paragraph size="s2" marginBottom="none">
          {changeAnyDetails.text2}
        </Paragraph>
      </ContentGroup>

      <Hr />

      <Heading size="s5" marginBottom="05" as="h2"
        data-testid='residential-mortgages-heading'>
        {residentialMortgages.title}
      </Heading>

      <Paragraph>{residentialMortgages.text}</Paragraph>

      <Paragraph weight="bold" marginBottom="02"
        data-testid='already-have-residential-mortgage-subheading'>
        {residentialMortgages.secondTitle}
      </Paragraph>

      <Paragraph>{residentialMortgages.text2}</Paragraph>

      <Paragraph weight="bold" marginBottom="02"
        data-testid='apply-for-additional-borrowing-subheading'>
        {residentialMortgages.thirdTitle}
      </Paragraph>

      <Paragraph>{residentialMortgages.text3}</Paragraph>

      <Paragraph>{residentialMortgages.text4}</Paragraph>

      <Heading size='s4' marginBottom='05' as='h3'
        data-testid='help-and-support-subheading'>
        {residentialMortgages.helpAndSupport.heading}
      </Heading>
      <Paragraph>{residentialMortgages.helpAndSupport.text1}</Paragraph>
      <Paragraph>{residentialMortgages.helpAndSupport.text2}</Paragraph>
      <Paragraph><StyledLink to={routes.FinancialDifficulties}>{residentialMortgages.helpAndSupport.financialDifficultiesLinkLabel}</StyledLink></Paragraph>

      <Heading size='s4' marginBottom='05' as='h3'
        data-testid='make-interest-only-payment-subheading'>
        {residentialMortgages.interestOnlyPayments.heading}
      </Heading>
      <Paragraph>{residentialMortgages.interestOnlyPayments.text1}</Paragraph>
      <Strong>{residentialMortgages.interestOnlyPayments.boldText}</Strong>
      <List marginBottom='none' marginTop='03'>
        {residentialMortgages.interestOnlyPayments.listItems.map((item: string) => (
          <ListItem key={item}>{item}</ListItem>
        ))}
      </List>
      <Paragraph marginTop='05'><Link external href={residentialMortgages.interestOnlyPayments.interestOnlyLinkHref}>{residentialMortgages.interestOnlyPayments.interestOnlyLinkLabel}</Link></Paragraph>

      <Heading size='s4' marginBottom='05' as='h3'
        data-testid='cancelling-interest-only-subheading'>
        {residentialMortgages.cancellingInterestOnly.heading}
      </Heading>
      <Paragraph>
        {residentialMortgages.cancellingInterestOnly.text1}
        <Link external href={residentialMortgages.cancellingInterestOnly.cancellationLinkHref}>{residentialMortgages.cancellingInterestOnly.cancellationLinkLabel}</Link>
        {residentialMortgages.cancellingInterestOnly.text2}
      </Paragraph>
      <Paragraph>{residentialMortgages.cancellingInterestOnly.text3}</Paragraph>

      <Heading size='s4' marginBottom='05' as='h3'
        data-testid='extend-mortgage-term-subheading'>
        {residentialMortgages.extendMortgageTerm.heading}
      </Heading>
      <Paragraph>{residentialMortgages.extendMortgageTerm.text1}</Paragraph>
      <Strong>{residentialMortgages.extendMortgageTerm.boldText}</Strong>
      <List marginBottom='none' marginTop='03'>
        {residentialMortgages.extendMortgageTerm.listItems.map((item: string) => (
          <ListItem key={item}>{item}</ListItem>
        ))}
      </List>
      <Paragraph marginTop='05'>
        {residentialMortgages.extendMortgageTerm.text2}
        <Link href={`tel:${residentialMortgages.extendMortgageTerm.contactLinkLabel}`}>{residentialMortgages.extendMortgageTerm.contactLinkLabel}</Link>
        {residentialMortgages.extendMortgageTerm.text3}
      </Paragraph>
    </Main>
  );
}

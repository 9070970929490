export const Accessibility = '/accessibility';
export const BankOfEnglandBaseRate = '/bank-of-england-base-rate';
export const Complaints = '/complaints';
export const ContactUs = '/contact-us';
export const CookiesPolicy = '/cookies-policy';
export const Eligibility = '/eligibility';
export const FeesAndCharges = '/fees-and-charges';
export const FinancialDifficulties = '/financial-difficulties';
export const Home = '/';
export const HowToMakeAPayment = '/how-to-make-a-payment';
export const InterestAndProducts = '/interest-and-products';
export const KnowledgeBase = '/knowledge-base';
export const Lending = '/lending';
export const MakingOverpayments = '/making-overpayments';
export const ManageYourMortgage = '/manage-your-mortgage';
export const MortgageCalculators = '/mortgage-calculators';
export const MortgagePaymentCalculator ='/mortgage-calculators/mortgage-payment-calculator';
export const OurMortgages = '/our-mortgages';
export const Payments = '/payments';
export const PortfolioLandlord = '/portfolio-landlord';
export const PrivacyPolicy = '/privacy-policy';
export const PropertyDeeds = '/your-property-deeds';
export const RateChangeCalculator ='/mortgage-calculators/rate-change-calculator';
export const RedirectToPrivacyPolicy ='/existing-customers/security-and-privacy';
export const SiteMap = '/site-map';
export const Sustainability = '/sustainability';
export const TermsAndConditions = '/existing-customers/terms';

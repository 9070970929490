import React from 'react';

import { Heading, Main, Paragraph } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import bmCalculatorIllustration from 'assets/common/BM_illustration_calculator_white.png';
import ContentBox from 'client/components/contentBox/ContentBox';
import GradientBox from 'client/components/gradientBox/GradientBox';
import { NavigationTiles } from 'client/components/navigationTiles';
import testIds from 'client/testIds';
import { Helmet } from 'react-helmet';

import { MortgageCalculatorsPageContent } from './MortgageCalculatorsPage.config';

export default function MortgageCalculatorsPage() {
  const { hero, navigationTiles, metaContent } =
    useContent<MortgageCalculatorsPageContent>();
  return (
    <Main>
      <Helmet>
        <title>{metaContent.title}</title>
        <meta name="description" content={metaContent.description} />
      </Helmet>
      <GradientBox>
        <ContentBox
          imgSrc={bmCalculatorIllustration}
          leftAlignImage={false}
          imgWidth="200px"
          marginBottom="none"
          marginTop="none"
          imgTestId={testIds.mortgageCalculatorsPage.headerImg}
        >
          <Heading
            size="s7"
            inverse
            data-testid={testIds.mortgageCalculatorsPage.headerTitle}
          >
            {hero.heading}
          </Heading>
          <Paragraph
            inverse
            data-testid={testIds.mortgageCalculatorsPage.headerFirstParagraph}
          >
            {hero.firstParagraph}
          </Paragraph>
          <Paragraph
            inverse
            data-testid={testIds.mortgageCalculatorsPage.headerSecondParagraph}
          >
            {hero.secondParagraph}
          </Paragraph>
        </ContentBox>
      </GradientBox>
      <NavigationTiles
        navigationTiles={navigationTiles}
        pageTestId={testIds.mortgageCalculatorsPage.mortgageCalculatorsTiles}
      />
    </Main>
  );
}

import React, { ReactElement } from 'react';

import {
  ContentGroup,
  Main,
  Link,
  Heading,
  Grid,
  GridItem,
  Paragraph,
  ListItem,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import lightbulbImage from 'assets/common/Lightbulb_white_CMYK.png';
import ContentBox from 'client/components/contentBox/ContentBox';
import GradientBox from 'client/components/gradientBox/GradientBox';
import InfoWithButton from 'client/components/infoWithButton/InfoWithButton';
import { StyledList } from 'client/components/sectionBody/SectionBody.styled';
import testIds from 'client/testIds';
import { Helmet } from 'react-helmet';

import { SustainabilityContent } from './SustainabilityPage.config';

export default function SustainabilityPage() {
  const {
    heading,
    subheading,
    governmentSchemes,
    epcRatingSection,
    getEPCAssessment,
    retrofitTool,
    metaContent,
  } = useContent<SustainabilityContent>();
  return (
    <Main>
      <Helmet>
        <title>{metaContent.title}</title>
        <meta name="description" content={metaContent.description} />
      </Helmet>
      <GradientBox>
        <ContentBox
          imgSrc={lightbulbImage}
          leftAlignImage={false}
          imgWidth="150px"
          marginBottom="none"
          marginTop="none"
          imgTestId={testIds.sustainability.headerImg}
        >
          <Heading
            size="s7"
            as="h1"
            inverse
            data-testid={testIds.sustainability.headerTitle}
          >
            {heading}
          </Heading>
          <Paragraph
            inverse
            data-testid={testIds.sustainability.headerSubheading}
          >
            {subheading}
          </Paragraph>
        </ContentBox>
      </GradientBox>
      <Grid>
        <GridItem lg={8}>
          <ContentGroup>
            <Heading
              data-testid={testIds.sustainability.epcRatingHeading}
              size="s5"
              as="h2"
            >
              {epcRatingSection.heading}
            </Heading>
            <Paragraph
              data-testid={testIds.sustainability.epcRatingFirstParagraph}
            >
              {epcRatingSection.firstParagraph}
            </Paragraph>
            <Paragraph
              data-testid={testIds.sustainability.epcRatingSecondParagraph}
            >
              {epcRatingSection.secondParagraph}
            </Paragraph>
            <Link
              external
              data-testid={testIds.sustainability.epcRatingLinkEng}
              href={epcRatingSection.checkEPCLinkEngland.url}
            >
              {epcRatingSection.checkEPCLinkEngland.label}
            </Link>
            <Paragraph
              data-testid={testIds.sustainability.epcRatingThirdParagraph}
              marginTop="05"
            >
              {epcRatingSection.thirdParagraph}
            </Paragraph>

            <Link
              external
              data-testid={testIds.sustainability.epcRatingLinkScot}
              href={epcRatingSection.checkEPCLinkScotland.url}
            >
              {epcRatingSection.checkEPCLinkScotland.label}
            </Link>
          </ContentGroup>
          <ContentGroup>
            <Heading
              size="s5"
              data-testid={testIds.sustainability.epcAssessmentHeading}
              as="h2"
            >
              {getEPCAssessment.heading}
            </Heading>
            <Paragraph
              data-testid={testIds.sustainability.epcAssessmentFirstParagraph}
            >
              {getEPCAssessment.firstParagraph}
            </Paragraph>
            <Paragraph
              data-testid={testIds.sustainability.epcAssessmentSecondParagraph}
            >
              {getEPCAssessment.secondParagraph}
            </Paragraph>
          </ContentGroup>
          <ContentGroup>
            <Heading
              size="s5"
              data-testid={testIds.sustainability.governmentSchemesHeading}
              as="h2"
            >
              {governmentSchemes.heading}
            </Heading>
            <Paragraph
              data-testid={
                testIds.sustainability.governmentSchemesFirstParagraph
              }
            >
              {governmentSchemes.firstParagraph}
            </Paragraph>

            {governmentSchemes.listSection && (
              <GridItem>
                <StyledList>
                  {governmentSchemes.listSection.map(
                    (listItem: ReactElement, index) => (
                      <ListItem
                        data-testid={`government-schemes-list-item-${index}`}
                        key={`defined-list-item-${listItem}`}
                      >
                        {listItem}
                      </ListItem>
                    ),
                  )}
                </StyledList>
              </GridItem>
            )}
          </ContentGroup>
        </GridItem>
        <GridItem lg={4}>
          <InfoWithButton
            heading={retrofitTool.heading}
            headingLevel="h3"
            paragraph={retrofitTool.paragraph}
            buttonLabel={retrofitTool.buttonLabel}
            buttonUrl={retrofitTool.buttonUrl}
            testId={testIds.sustainability.retrofitToolInfoBox}
          />
        </GridItem>
      </Grid>
    </Main>
  );
}

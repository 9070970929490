import React from 'react';

import { Link, Text } from '@constellation/core';
import { AppContent } from 'content/AppContent';

import { MetaContentValues } from '../metaContentValues';

const links = {
  checkEPCLinkEngland: {
    href: 'https://www.gov.uk/find-energy-certificate',
    label:
      "Check your property's EPC rating in England, Wales, and Northern Ireland.",
  },

  scottishNetZero: {
    href: 'https://www.gov.scot/binaries/content/documents/govscot/publications/consultation-paper/2023/11/delivering-net-zero-scotlands-buildings-consultation-proposals-heat-buildings-bill/documents/delivering-net-zero-scotlands-buildings-consultation-proposals-heat-buildings-bill/delivering-net-zero-scotlands-buildings-consultation-proposals-heat-buildings-bill/govscot%3Adocument/delivering-net-zero-scotlands-buildings-consultation-proposals-heat-buildings-bill.pdf?optoutmulti=0:0%7Cc1:1%7Cc3:0%7Cc5:0%7Cc4:0%7Cc2:0&optmessage=1',
    label: "Scottish Government's net zero policy.",
  },

  checkEPCLinkScotland: {
    href: 'https://www.scottishepcregister.org.uk/CustomerFacingPortal/TermsAndConditions',
    label: "Check your property's EPC rating in Scotland",
  },

  getEPCAssessement: {
    href: 'https://www.gov.uk/get-new-energy-certificate',
    label: 'here',
  },

  registerExemption: {
    href: 'https://www.gov.uk/government/publications/private-rented-sector-minimum-energy-efficiency-standard-exemptions/guidance-on-prs-exemptions-and-exemptions-register-evidence-requirements',
    label: 'register an exemption.',
  },

  saveEnergyInYourHome: {
    href: 'https://www.gov.uk/improve-energy-efficiency',
    label: 'Find Ways to Save Energy in Your Home',
  },

  saveEnergyInYourHomeScotland: {
    href: 'https://www.homeenergyscotland.org',
    label: 'Scotland',
  },

  saveEnergyInYourHomeNI: {
    href: 'https://www.nihe.gov.uk/home',
    label: 'Northern Ireland',
  },

  warmerHomesScotland: {
    href: 'https://www.mygov.scot/energy-saving-funding/warmer-homes-scotland-scheme',
    label: 'Warmer Homes Scotland',
  },

  privateRentedLanlordScheme: {
    href: 'https://www.homeenergyscotland.org/support-private-sector-landlords',
    label: 'Private Rented Sector Landlord Loan schemes',
  },

  affordableWarmthSchemeNI: {
    href: 'https://www.nihe.gov.uk/housing-help/affordable-warmth/affordable-warmth-scheme',
    label: 'Affordable Warmth Scheme',
  },

  nestSchemeWales: {
    href: 'https://www.gov.wales/get-help-energy-efficiency-your-home-nest',
    label: 'Nest Scheme',
  },

  energyObligationScheme: {
    href: 'https://www.gov.uk/energy-company-obligation',
    label: 'Energy Company Obligation Scheme',
  },

  britishInsulationScheme: {
    href: 'https://www.gov.uk/apply-great-british-insulation-scheme',
    label: 'Great British Insulation Scheme',
  },

  boilerUpgradeScheme: {
    href: 'https://www.gov.uk/apply-boiler-upgrade-scheme',
    label: 'Boiler Upgrade Scheme',
  },

  smartExport: {
    href: 'https://www.which.co.uk/reviews/solar-panels/article/smart-export-guarantee-explained-at2wh8b519s2',
    label: 'Smart Export Guarantee',
  },
};

const sustainability: AppContent['sustainability'] = {
  metaContent: MetaContentValues.sustainability,
  heading: 'Sustainability',
  subheading:
    'Find out more about your obligations as a landlord and what government schemes are available to help you and your tenants make your property more sustainable.',
  epcRatingSection: {
    heading: 'Energy performance certificate (EPC) ratings',
    firstParagraph:
      "If you're a landlord in England and Wales, your property must have an EPC rating of at least an E to let it out, unless you've registered an exemption.",
    secondParagraph:
      "Mortgage lenders will ask to see your EPC rating as part of their lending decision. You could be stopped from renting your property out and fined if you don't have a high enough rating.",
    checkEPCLinkEngland: {
      label: links.checkEPCLinkEngland.label,
      url: links.checkEPCLinkEngland.href,
    },
    thirdParagraph: (
      <Text>
        {' '}
        In Scotland and Northern Ireland, you don't need a minimum EPC rating as
        a landlord. But this could change in-line with the{' '}
        <Link external href={links.scottishNetZero.href}>
          {links.scottishNetZero.label}
        </Link>
      </Text>
    ),
    checkEPCLinkScotland: {
      label: links.checkEPCLinkScotland.label,
      url: links.checkEPCLinkScotland.href,
    },
  },
  getEPCAssessment: {
    heading: 'Getting an EPC assessment',
    firstParagraph: (
      <Text>
        You can find accredited EPC assessors{' '}
        <Link external href={links.getEPCAssessement.href}>
          {links.getEPCAssessement.label}
        </Link>
        . An EPC is valid for 10 years and costs around £40.
      </Text>
    ),
    secondParagraph: (
      <Text>
        If you need to make improvements to bring your EPC rating to at least an
        E, there's a 'cost cap' of £3,500. This means you're only required to
        spend up to £3,500 to boost your property's energy efficiency. If it
        still doesn't meet the E-rating requirement after that, you can{' '}
        <Link external href={links.registerExemption.href}>
          {links.registerExemption.label}
        </Link>
      </Text>
    ),
  },

  governmentSchemes: {
    heading: 'Government schemes',
    firstParagraph:
      "Here are some of the current schemes available that could help you and your tenant. Many energy companies also offer their own schemes. These can change or expire, so always check what's available before doing anything.",
    listSection: [
      <Text>
        The Government's{' '}
        <Link external href={links.saveEnergyInYourHome.href}>
          {links.saveEnergyInYourHome.label}
        </Link>{' '}
        for England and Wales offers recommendations for energy-efficient home
        improvements.{' '}
        <Link external href={links.saveEnergyInYourHomeScotland.href}>
          {links.saveEnergyInYourHomeScotland.label}
        </Link>{' '}
        and{' '}
        <Link external href={links.saveEnergyInYourHomeNI.href}>
          {links.saveEnergyInYourHomeNI.label}
        </Link>{' '}
        also have similar schemes.
      </Text>,
      <Text>
        Scotland also has{' '}
        <Link external href={links.warmerHomesScotland.href}>
          {links.warmerHomesScotland.label}
        </Link>{' '}
        and the{' '}
        <Link external href={links.privateRentedLanlordScheme.href}>
          {links.privateRentedLanlordScheme.label}
        </Link>
        .
      </Text>,
      <Text>
        In Northern Ireland, check out the Housing Executive's{' '}
        <Link external href={links.affordableWarmthSchemeNI.href}>
          {links.affordableWarmthSchemeNI.label}
        </Link>{' '}
        for low-income households.
      </Text>,
      <Text>
        The Welsh Government's{' '}
        <Link external href={links.nestSchemeWales.href}>
          {links.nestSchemeWales.label}
        </Link>{' '}
        provides free, impartial energy advice
      </Text>,
      <Text>
        The{' '}
        <Link external href={links.energyObligationScheme.href}>
          {links.energyObligationScheme.label}
        </Link>{' '}
        could help your tenants with certain energy-saving home improvements.
      </Text>,
      <Text>
        The{' '}
        <Link external href={links.britishInsulationScheme.href}>
          {links.britishInsulationScheme.label}
        </Link>{' '}
        provides free or cheaper insulation for properties in England, Scotland,
        and Wales.
      </Text>,
      <Text>
        With the{' '}
        <Link external href={links.boilerUpgradeScheme.href}>
          {links.boilerUpgradeScheme.label}
        </Link>{' '}
        (BUS), you can apply for a grant to help with installing air or ground
        source heat pumps, or a biomass boiler in your property.
      </Text>,
      <Text>
        The{' '}
        <Link external href={links.smartExport.href}>
          {links.smartExport.label}
        </Link>{' '}
        (SEG) is a government-backed scheme that means you can get paid for
        renewable electricity you've generated and not used.
      </Text>,
    ],
  },

  retrofitTool: {
    heading: 'Try our Landlord Retrofit Tool',
    paragraph:
      'Our Landlord Retrofit Tool is great for working out just how green your property is and shows how a few changes could make it more energy-efficient.',
    buttonLabel: 'Landlord Retrofit Tool',
    buttonUrl:
      'https://home-energy-saving-tool.bmsolutions.co.uk/WhereDoYouLive',
  },
};

export default sustainability;

import { AppContent } from 'content/AppContent';

import * as routes from '../../routes/manifest';
import { MetaContentValues } from '../metaContentValues';

const knowledgeBase: AppContent['knowledgeBase'] = {
  metaContent: MetaContentValues.knowledgeBase,
  pageTitle: 'Knowledge base',
  bannerSubheader: 'Already  got a mortgage with us? Find out how to manage it online, borrow more, switch  your deal, and everything else you need to know.',
  bannerText: 'Explore the sections below to get started.',

  navigationTiles: [
    {
      linkText: 'How to make a payment to your mortgage',
      boxText:
        'We have a range of ways you can make your monthly mortgage payment.',
      url: routes.HowToMakeAPayment,
    },
    {
      linkText: `Overpayments`,
      boxText: `How to make an overpayment to your mortgage.`,
      url: routes.MakingOverpayments,
    },
    {
      linkText: `Interest rates and our products`,
      boxText: `You can find your interest rates on your mortgage offer and latest yearly statement.`,
      url: routes.InterestAndProducts,
    },
    {
      linkText: `Questions about lending`,
      boxText: `If you have any general lending questions about your mortgage, you can get them answered here`,
      url: routes.Lending,
    },
    {
      linkText: `The Bank of England base rate and your mortgage`,
      boxText: `The Bank of England (BoE) sets the official UK base rate. This influences the interest rates set by banks and other lenders.`,
      url: routes.BankOfEnglandBaseRate,
    },
    {
      linkText: 'Fees and charges',
      boxText:
        'Some of our extra services have added fees or charges. You can find out what these are in this section.',
      url: routes.FeesAndCharges,
    },
    {
      linkText: 'Worried about future payments?',
      boxText:
        'Please get in touch. The sooner you contact us, the sooner we can help.',
      url: routes.FinancialDifficulties,
    },
    {
      linkText: 'Complaints',
      boxText:
        'If you have a complaint about any of our services or products, please get in touch.',
      url: routes.Complaints,
    },
    {
      linkText: 'Your property deeds',
      boxText: 'Not sure where your property deeds are held or how to get your hands on them? Find out more here.',
      url: routes.PropertyDeeds,
    }
  ],
};

export default knowledgeBase;

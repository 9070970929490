import testIds from "client/testIds";
import { AppContent } from "content/AppContent";

import * as routes from '../../routes/manifest';
import { MetaContentValues } from "../metaContentValues";

const mortgageCalculators: AppContent['mortgageCalculators'] = {
  metaContent: MetaContentValues.mortgageCalculators,
  hero: {
      heading: "Mortgage calculators",
      firstParagraph: "Our mortgage calculators can make things easier to understand for you. Simply select the one that suits your need and answer a few questions.",
      secondParagraph: "You might need your up-to-date mortgage balance, remaining term, and normal monthly payment amount to hand before you start."
   },
   navigationTiles: [
      {
         linkText: 'Rate change calculator',
         boxText: 'Use our rate change calculator to get an idea of how much your monthly mortgage payment might change',
         url: routes.RateChangeCalculator,
         linkTestId: testIds.mortgageCalculatorsPage.rateChangeCalculatorLink,
      },
      {
         linkText: 'Mortgage payment calculator',
         boxText: 'Try our calculator to help you understand how much your monthly mortgage payments might be.',
         url: routes.MortgagePaymentCalculator,
         linkTestId: testIds.mortgageCalculatorsPage.mortgagePaymentCalculatorLink,
      }
   ]
}

export default mortgageCalculators;
import React from 'react';

import {
  Box,
  ContentGroup,
  Grid,
  GridItem,
  Heading,
  Hr,
  List,
  ListItem,
  Main,
  Paragraph,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { InfoPanel } from 'client/components/infoPanel';
import { StyledLink } from 'client/components/styledLink/StyledLink';
import { useScreen } from 'client/context/ScreenContext';
import testIds from 'client/testIds';
import { Helmet } from 'react-helmet';

import { BankOfEnglandBaseRateContent } from './BankOfEnglandBaseRate.config';
import * as routes from '../../manifest';

function BankOfEnglandBaseRate(): React.JSX.Element {
  const { pageHeading, infoPanel, sidePanel } =
    useContent<BankOfEnglandBaseRateContent>();

  const { isMobile } = useScreen();

  return (
    <Main>
      <Helmet>
        <title>
          Bank of England base rate | Knowledge Base | Birmingham Midshires{' '}
        </title>
      </Helmet>
      <ContentGroup marginTop="05">
        <StyledLink
          iconPosition="left"
          data-testid="back-button-link"
          to={routes.KnowledgeBase}
        >
          Back
        </StyledLink>
        <Heading as="h1" marginTop="05" size="s7">
          {pageHeading}
        </Heading>
        <Hr />
        <Grid reversed={!isMobile}>
          <GridItem sm={12} md={4}>
            <Box>
              <Heading
                as="h2"
                size="s4"
                data-testid="boe-announcement-panel-heading"
              >
                {sidePanel.heading}
              </Heading>
              <Paragraph data-testid="boe-announcement-panel-paragraph-1">
                {sidePanel.paragraph1}
              </Paragraph>
              <Paragraph data-testid="boe-announcement-panel-paragraph-2">
                {sidePanel.paragraph2}
              </Paragraph>
              <List marginBottom="04">
                {sidePanel.list.map((item, idx) => (
                  <ListItem
                    key={item}
                    data-testid={`boe-announcement-panel-list-${idx}`}
                  >
                    {item}
                  </ListItem>
                ))}
              </List>
              <Paragraph
                marginBottom="01"
                data-testid="boe-announcement-panel-paragraph-3"
              >
                {sidePanel.paragraph3}
              </Paragraph>
            </Box>
          </GridItem>
          <GridItem sm={12} md={8}>
            <InfoPanel
              pageTestIds={testIds.bankOfEnglandBaseRate}
              infoPanel={infoPanel}
            />
          </GridItem>
        </Grid>
      </ContentGroup>
    </Main>
  );
}

export default BankOfEnglandBaseRate;

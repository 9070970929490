import React from 'react';

import { Heading, Link, Main, Paragraph } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import houseOutline from 'assets/common/House_white_CMYK.png';
import ContentBox from 'client/components/contentBox/ContentBox';
import GradientBox from 'client/components/gradientBox/GradientBox';
import { NavigationTiles } from 'client/components/navigationTiles';
import testIds from 'client/testIds';
import { Helmet } from 'react-helmet';

import { OurMortgagesContent } from './OurMortgagesPage.config';

export default function OurMortgagesPage() {
  const ourMortgagesContent = useContent<OurMortgagesContent>();

  return (
    <Main>
      <Helmet>
        <title>{ourMortgagesContent.metaContent.title}</title>
        <meta
          name="description"
          content={ourMortgagesContent.metaContent.description}
        />
      </Helmet>
      <GradientBox>
        <ContentBox
          imgSrc={houseOutline}
          leftAlignImage={false}
          imgWidth="200px"
          marginBottom="none"
          marginTop="none"
          imgTestId={testIds.ourMortgagesPage.homeImage}
        >
          <Heading
            as="h1"
            size="s7"
            inverse
            data-testid={testIds.ourMortgagesPage.bannerHeading}
          >
            {ourMortgagesContent.pageTitle}
          </Heading>
          <Heading
            size="s3"
            inverse
            data-testid={testIds.ourMortgagesPage.subHeading}
          >
            {ourMortgagesContent.subHeading}
          </Heading>
          <Paragraph
            inverse
            data-testid={testIds.ourMortgagesPage.firstParagraph}
          >
            {ourMortgagesContent.firstParagraph}
          </Paragraph>
          <Paragraph
            inverse
            data-testid={testIds.ourMortgagesPage.secondParagraph}
          >
            {ourMortgagesContent.secondParagraph}
          </Paragraph>
          <Heading
            size="s2"
            inverse
            data-testid={testIds.ourMortgagesPage.mortgageBrokerLinkTitle}
          >
            {ourMortgagesContent.linkTitle}
          </Heading>
          <Link
            href={ourMortgagesContent.url}
            inverse
            external
            data-testid={testIds.ourMortgagesPage.mortgageBrokerLinkText}
          >
            {ourMortgagesContent.linkText}
          </Link>
        </ContentBox>
      </GradientBox>
      <NavigationTiles
        navigationTiles={ourMortgagesContent.navigationTiles}
        pageTestId={testIds.ourMortgagesPage.ourMortgagesTiles}
      />
    </Main>
  );
}

// keep 1 redirect rule per line - don't format this file with prettier
// keep lines in alphabetical order & groups of 5 to make them easier to read
// no paths we are redirecting should end in a trailing slash
import * as routes from 'client/routes/manifest';

export const InternalRedirectDefinitions = {
  '/contact-us/complaints-policy': routes.Complaints,
  '/contact-us/complaints-policy/ppi': routes.Complaints,
  '/contact-us/executive-team': routes.Complaints,
  '/contact-us/executive-team/form': routes.Complaints,
  '/existing-customers': routes.ManageYourMortgage,

  '/existing-customers/base-rate-calculator': routes.RateChangeCalculator,
  '/existing-customers/base-rate-information': routes.BankOfEnglandBaseRate,
  '/existing-customers/deeds': routes.PropertyDeeds,
  '/existing-customers/deeds/information-for-customers': routes.PropertyDeeds,
  '/existing-customers/deeds/information-for-solicitors': routes.PropertyDeeds,

  '/existing-customers/endowments': routes.ManageYourMortgage,
  '/existing-customers/facing-financial-difficulty': routes.FinancialDifficulties,
  '/existing-customers/fees-and-charges': routes.FeesAndCharges,
  '/existing-customers/interest-only-mortgages': routes.ContactUs,
  '/existing-customers/interest-rate-information': routes.InterestAndProducts,

  '/existing-customers/mortgage-support': routes.ManageYourMortgage,
  '/existing-customers/overpayments': routes.MakingOverpayments,
  '/existing-customers/payment-queries': routes.HowToMakeAPayment,
  '/existing-customers/personal-changes': routes.ManageYourMortgage,
  '/existing-customers/questions': routes.ManageYourMortgage,

  '/existing-customers/questions/#rent-property': routes.ManageYourMortgage,
  '/existing-customers/rate-and-product-queries': routes.InterestAndProducts,
  '/existing-customers/ring-fencing': routes.ContactUs,
  '/existing-customers/statement-information': routes.ManageYourMortgage,
  '/existing-customers/sustainability-hub': routes.Sustainability,

  '/existing-customers/sustainability-hub/discounted-home-improvements': routes.Sustainability,
  '/existing-customers/sustainability-hub/greener-housing-market': routes.Sustainability,
  '/existing-customers/sustainability-hub/step-by-step-guide': routes.Sustainability,
  '/existing-customers/tariff-of-mortgage-charges': routes.FeesAndCharges,
  '/existing-customers/things-to-consider': routes.ManageYourMortgage,

  '/financial-difficulty': routes.FinancialDifficulties,
  '/help': routes.FinancialDifficulties,
  '/income': routes.FinancialDifficulties,
  '/moneysupport': routes.FinancialDifficulties,
  '/moneyworries': routes.FinancialDifficulties,

  '/mortgage-ph-options': routes.ContactUs,
  '/mortgage-support': routes.ManageYourMortgage,
  '/renting-your-home': routes.ManageYourMortgage,
  '/site_map': routes.SiteMap,
  '/sustainability/discounted-home-improvements': routes.Sustainability,
  
  '/switch-rate': routes.FinancialDifficulties,
};

import * as routes from '../../routes/manifest';
import { AppContent } from "../AppContent";

const siteMap: AppContent['siteMap'] = {
  heading: 'Sitemap',

  home: "Home",
  ourMortgages: "Our mortgages",
  eligibility: "Eligibility",
  portfolioLandlord: "Portfolio landlord",
  manageYourMortgage: "Manage your mortgage",
  mortgageCalculators: "Mortgage calculators",
  rateChangeCalculator: "Rate change calculator",
  mortgagePaymentCalculator: "Mortgage payment calculator",
  sustainability: "Sustainability",
  knowledgeBase: "Knowledge base",

  knowledgeBaseArticleLabels: [
    "How to make a payment",
    "Making overpayments",
    "Interest and products",
    "Lending",
    "Bank of England base rate",
    "Fees and charges",
    "Financial difficulties",
    "Complaints",
    "Property deeds"
  ],
  knowledgeBaseArticlesHrefs: [
    routes.HowToMakeAPayment,
    routes.MakingOverpayments,
    routes.InterestAndProducts,
    routes.Lending,
    routes.BankOfEnglandBaseRate,
    routes.FeesAndCharges,
    routes.FinancialDifficulties,
    routes.Complaints,
    routes.PropertyDeeds
  ],

  contactUs: "Contact us",

  termsAndConditions: "Terms and conditions",
  accessibility: "Accessibility",
  cookiesPolicy: "Cookies policy",
  privacyPolicy: "Privacy policy",

}

export default siteMap;
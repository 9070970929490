// we need a separate file so that both the server build & client build can share the same content file, attempting to use
// the content files from the client folder will break if they contain anything other than text (eg React components)
import { MetaContent } from './MetaContent';

const home: MetaContent = {
  title: 'Homepage | Birmingham Midshires',
  description:
    "Birmingham Midshires is a specialist buy to let provider, we want you to feel confident you've made the right decision with your mortgage",
};

const ourMortgages: MetaContent = {
  title: 'Our mortgages | Birmingham Midshires',
  description:
    'Birmingham Midshires has a range of buy to let mortgages to suit your needs, whether you have a single property or lots of different properties to rent out',
};

const manageYourMortgage: MetaContent = {
  title: 'Managing your mortgage | Birmingham Midshires',
  description:
    'Find out how to manage your Birmingham Midshires mortgage online, borrow more, switch your deal, and everything else you need to know',
};

const mortgageCalculators: MetaContent = {
  title: 'Calculators | Birmingham Midshires',
  description:
    'Our mortgage calculators can make things easier to understand for you. Simply select the one that suits your need and answer a few questions',
};

const sustainability: MetaContent = {
  title: 'Sustainability | Birmingham Midshires',
  description:
    'Find out about your obligations as a landlord and what government schemes are available to help you and your tenants make your property more sustainable',
};

const knowledgeBase: MetaContent = {
  title: 'Knowledge base | Birmingham Midshires',
  description:
    'Already got a mortgage with Birmingham Midshires? Explore the knowledge base sections to find everything you need to know',
};

const contactUs: MetaContent = {
  title: 'Contact us | Birmingham Midshires',
  description:
    'Want to get in touch? Find the contact details you need for Birmingham Midshires mortgages',
};

export const MetaContentValues = {
  home,
  ourMortgages,
  manageYourMortgage,
  mortgageCalculators,
  sustainability,
  knowledgeBase,
  contactUs,
};
